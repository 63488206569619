module.exports = [{
      plugin: require('/codebuild/output/src723927257/src/hamerlin.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-107777050-1","head":false,"anonymize":true,"respectDNT":true,"sampleRate":5,"siteSpeedSampleRate":10},
    },{
      plugin: require('/codebuild/output/src723927257/src/hamerlin.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src723927257/src/hamerlin.com/site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
